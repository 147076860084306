var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"decisionMake",attrs:{"id":"mainContent"}},[_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"01","label":"会议基本信息","data-title":"会议基本信息"}}),_c('form-info',{attrs:{"componentList":_vm.financingConfig,"model":_vm.formData,"showbor":false,"count":_vm.count}})],1),_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"02","label":"会议纪要","data-title":"会议纪要"}}),_c('title-center',{attrs:{"name":"评审会问题及落实情况"}}),_c('base-table',{ref:"tableData",staticClass:"order-table",attrs:{"columns":_vm.problemConfig,"showPage":false,"tableAttrs":{
        data: _vm.formData.summaryDetailVOS,
        stripe: true,
      },"is-caculate-height":false,"webPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])}),_c('title-center',{attrs:{"name":"风控经理信息补充"}}),_c('base-table',{ref:"tableData",staticClass:"order-table",attrs:{"columns":_vm.measuresConfig,"showPage":false,"tableAttrs":{
        data: _vm.formData.meetingReplenishDTOS,
        stripe: true,
      },"is-caculate-height":false,"webPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])})],1),_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"03","label":"本次申请授信方案","data-title":"本次申请授信方案"}}),_c('form-info',{attrs:{"componentList":_vm.programmeConfig,"model":_vm.formData,"showbor":false,"count":_vm.count}})],1),_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"04","label":"文件生成与下载","data-title":"文件生成与下载"}}),_c('base-form',{ref:"fileConfig",staticClass:"formData",attrs:{"componentList":_vm.fileConfig,"formAttrs":{
        model: _vm.formData.fileListInfo,
        labelWidth: '100px',
      },"showBtns":false}})],1),_c('div',{staticClass:"bottom"},[_c('base-button',{attrs:{"label":"关闭","type":"default"},on:{"click":_vm.close}})],1),_c('scroll-fool',{ref:"scrollFool"}),_c('pre-view',{attrs:{"fileId":_vm.fileId,"isOpen":true,"fileType":_vm.fileType,"count":_vm.fileIdCount}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }